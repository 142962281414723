@font-face {
  font-family: ThankYouSoMuch;
  src: url("./assets/font/thank_you_so_much.otf") format("opentype");
}

@font-face {
  font-family: 'Avenir Next Demi';
  src: url('./assets/font/avenir-next-demi.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  padding: 0px;
  margin: 0px;
  font-family: "Poppins", sans-serif;
}

* {
  box-sizing: border-box;
}
.MuiInput-underline:before {
  display: none;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}

.MuiInput-underline:after {
  border: none !important;
}

.MuiSlider-marked {
  margin-bottom: 0px !important;
}

.MuiSlider-mark {
  display: none;
}

::-webkit-scrollbar {
  width: 4px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #18171702; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #f30e3e; /* color of the scroll thumb */
  border-radius: 4px; /* roundness of the scroll thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #eb0c29; /* color of the scroll thumb on hover */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #888 #f1f1f1; /* thumb and track color */
}

.zoom-container {
  overflow: hidden;
  position: relative;
  width: 100%; /* Set to desired width */
  height: auto; /* Set to desired height */
  cursor: pointer;
}

.zoom-container img {
  width: 100%;
  height: auto;
  transition: transform 0.2s ease-out;
}

.flag button {
  height: 56px;
}

.signup-join-as {
  padding: 20px 10px;
  border: 2px solid #d9d9d9;
  border-radius: 6px;
  margin: 10px 0;
  position: relative;
}

.signup-join-as:hover {
  border-color: #108a00;
}

.signup-join-as:active {
  transform: scale(0.98);
}

.signup-join-as.active{
  border-color: #108a00;
}

.signup-join-as input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  margin: 0;
}

.signup-join-as .select-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signup-join-as .select-bar .icon-wrapper {
  width: 24px;
  height: 24px;
  background: white;
  border: 2px solid #d9d9d9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-join-as.active .select-bar .icon-wrapper {
  background: #108a00;
  border-color: #108a00;
}

.signup-join-as.active .select-bar .icon-content {
  width: 12px;
  height: 12px;
  background: #108a00;
  border: 2px solid white;
  border-radius: 50%;
}

.signup-join-as h4 {
  margin-top: 15px;
}

.link-active {
  color: black;
  font-weight: 800;
}